export const safeParse = (what, fallback) => {
  try {
    return JSON.parse(what);
  } catch (e) {
    return fallback;
  }
};

export const asNumber = (what) => {
  if (typeof what === 'undefined') {
    return what;
  }

  const number = parseInt(what, 10);

  return Number.isNaN(number) ? 0 : number;
};

export const asBoolean = (what) => {
  if (typeof what === 'undefined') {
    return what;
  }

  return what === 'true';
};

export const MPS_QUEUE_TYPES = {
  adload: 'adload',
  ready: 'mpsready',
};

export const getMpsQueue = (qtype = MPS_QUEUE_TYPES.ready) => {
  window.mps = window.mps || {};
  window.mps._queue = window.mps._queue || {}; // eslint-disable-line
  window.mps._queue[qtype] = window.mps._queue[qtype] || []; // eslint-disable-line
  return window.mps._queue[qtype]; // eslint-disable-line
};

export const adUnitToCat = (adunit) => {
  const [, /* network */, /* dartSite */, mpsSect, mpsSub1] = adunit.split('/');
  const catParts = [mpsSect, mpsSub1].filter((part) => !!part);

  if (!catParts.length) {
    return 'home';
  }

  return catParts.join('|');
};

export const insertElement = (tag, attributes = {}, elementToInsertInto) => {
  if (!document) {
    return null;
  }

  const element = document.createElement(tag);

  Object.keys(attributes).forEach((key) => {
    const value = attributes[key];
    if (typeof value === 'boolean') {
      if (!value) {
        return;
      }

      element.setAttribute(key, key);
      return;
    }

    element.setAttribute(key, value);
  });

  elementToInsertInto.appendChild(element);
  return element;
};

export const getAdOptionsFromElement = (el) => {
  // offsetViewport is the percentage of viewport height. Example: If it's 100%
  // then the viewport height will be multiplied with 100% to give the new ad offset in Enigma.
  // Currently, only boxinline ads have this value. We do this on the front end side
  // as opposed to inside Enigma b/c we want to maintain Enigma as an ad library separated
  // from Ramen
  const {
    slot,
    sizes,
    offset,
    targeting,
    renderOnView,
    refreshInterval,
    activeTab,
    offsetViewport,
  } = el.dataset;

  return {
    slot,
    sizes: safeParse(sizes, []),
    targeting: safeParse(targeting, null),
    refreshInterval: refreshInterval === 'false' ? 0 : asNumber(refreshInterval),
    offset: asNumber(offset),
    renderOnView: asBoolean(renderOnView),
    offsetViewport: asNumber(offsetViewport),
    activeTab: asBoolean(activeTab),
  };
};

export const requestAnimationFrame = (fn) => {
  if (!window || typeof window.requestAnimationFrame === 'undefined') {
    const adTimeout = 66;
    setTimeout(fn, adTimeout);
  } else {
    window.requestAnimationFrame(fn);
  }
};

export const buildReferrer = (str) => str
  .replace(/^(https?:\/\/(www)?)/, '')
  .replace(/[./:]/g, '');

export const isBetween = (number, a, b) => number >= Math.min(a, b) && number <= Math.max(a, b);
