class AdRegister {
  static adInstances = [];

  static get instances() {
    return AdRegister.adInstances;
  }

  static findByElement(element) {
    return AdRegister.adInstances.find((ad) => ad.element === element);
  }

  static register(ad) {
    AdRegister.adInstances.push(ad);
  }
}

export default AdRegister;
