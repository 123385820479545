import ScrollMonitor from 'scrollmonitor';
import getPreprocessorTargeting from 'lib/Ads/preprocessors/getPreprocessorTargeting';
import onResize from 'lib/Ads/onResize';
import { buildReferrer, insertElement, isBetween } from 'lib/Ads/adUtils';
import AdRegister from 'lib/Ads/AdRegister';
import AdLifecycleManager, { AD_STATUS } from 'lib/Ads/AdLifecycleManager';
import MPS from 'lib/Ads/MPS';
import AdTimer from 'lib/Ads/AdTimer';

class Ad {
  id = null;

  ready = Promise.resolve();

  events = {};

  element = null;

  elementObserver = null;

  slot = null;

  options = {};

  refreshInterval = null;

  recycleOnBreakpoint = true;

  renderOnView = true;

  offset = 50;

  offsetViewport = null;

  activeTab = true;

  renderEventData = undefined;

  timer = null;

  timerWasPaused = false;

  scrollMonitor = null;

  onRenderCallbacks = [];

  adLifecycle = new AdLifecycleManager();

  constructor(element, { slot, ...options } = {}) {
    if (!window) {
      return;
    }

    if (!element || !slot) {
      throw new Error('Element and slot must be set');
    }

    this.id = MPS.getAdId(slot);
    this.slot = slot;
    this.element = element;

    AdRegister.register(this);
    this.adLifecycle.set(AD_STATUS.CREATING, true);

    this.ready = MPS.ready
      .then(async () => {
        const {
          renderOnView = true,
          recycleOnBreakpoint = true,
          refreshInterval = 0,
          offset = 50,
          offsetViewport,
          sizes,
          preprocessors = {},
          targeting: instanceTargeting = {},
          vendors,
          activeTab = true,
        } = options;

        this.activeTab = activeTab;
        this.options = options;
        this.renderOnView = renderOnView;

        // currently only apply to boxinline ads
        this.offset = typeof window !== 'undefined' && offsetViewport
          ? (window.innerHeight * offsetViewport) / 100
          : offset;

        this.recycleOnBreakpoint = recycleOnBreakpoint;
        this.refreshInterval = typeof refreshInterval !== 'undefined'
          ? refreshInterval
          : MPS.config.refreshInterval;

        const preprocessorTargeting = await getPreprocessorTargeting(preprocessors);

        const targeting = {
          ...MPS.targeting, // Page Specific Targeting
          ...instanceTargeting, // Ad Specific Targeting
          ...preprocessorTargeting, // Preprocessor Targeting
        };

        if (!('referrer' in targeting)) {
          targeting.referrer = document.referrer;
        }

        targeting.referrer = buildReferrer(targeting.referrer);

        this.setupElementObserver();

        this.ad = await MPS.createAd({
          id: this.id,
          targeting,
          element,
          slot,
          vendors,
          sizes,
        }, this.onInitialRender);

        this.adLifecycle
          .set(AD_STATUS.CREATING, false)
          .set(AD_STATUS.CREATED, true);

        this.scrollMonitor = ScrollMonitor.create(element, this.offset);
        this.scrollMonitor.stateChange(() => {
          this.scrollMonitor.recalculateLocation();
        });

        this.timer = new AdTimer();

        if (this.renderOnView) {
          this.setupRenderOnView();
        }
      });
  }

  render = async () => {
    await this.ready;

    if (this.adLifecycle.isProcessing() || this.adLifecycle.is(AD_STATUS.REQUESTED)) {
      return;
    }

    this.removeListeners();

    // have the ability to load ads even not in focus.
    if (!document.hasFocus() && this.activeTab) {
      this.renderOnFocus();
      return;
    }

    this.adLifecycle
      .set(AD_STATUS.RENDERING, true)
      .set(AD_STATUS.REQUESTING, true);

    await MPS.renderAd(this.ad);

    this.adLifecycle
      .doneProcessing()
      .set(AD_STATUS.REQUESTED, true);
  }

  recycle = async () => {
    await this.ready;

    if (this.adLifecycle.is(AD_STATUS.RECYCLING)) {
      return;
    }

    this.removeListeners();

    if (!document.hasFocus() && this.activeTab) {
      this.recycleOnFocus();
      return;
    }

    this.adLifecycle
      .clearFinishedStatuses()
      .set(AD_STATUS.RECYCLING, true)
      .set(AD_STATUS.REQUESTING, true);

    await MPS.recycleAd(this.ad);

    this.adLifecycle
      .doneProcessing()
      .set(AD_STATUS.REQUESTED, true)
      .set(AD_STATUS.RECYCLED, true);
  }

  destroy = async () => {
    await this.ready;

    this.adLifecycle
      .clearFinishedStatuses()
      .set(AD_STATUS.DESTROYING, true);

    await MPS.destroyAd(this.ad);

    this.adLifecycle
      .clearAllStatuses()
      .set(AD_STATUS.DESTROYED, true);

    this.removeListeners();
  }

  onInitialRender = (data = {}) => {
    if (!this.adLifecycle.is(AD_STATUS.REQUESTED) || this.adLifecycle.is(AD_STATUS.RENDERED)) {
      return;
    }

    this.adLifecycle.set(AD_STATUS.RENDERED, true);
    this.elementObserver.disconnect(); // element is rendered, we don't need the observer anymore
    this.scrollMonitor.destroy(); // we only want ads to refresh when we're 50% in view

    const adOffset = this.element.offsetHeight / -2 || this.offset;
    // destroy the scroll monitor so we remove the instance of previous ad offset.
    // create new scroll monitor for new add offset, new ad can change in size.
    this.scrollMonitor = ScrollMonitor.create(this.element, adOffset);

    if (this.refreshInterval) {
      this.scrollMonitor.recalculateLocation();
      this.setupRefreshInterval();
    }

    // on ad render we want to see if it's in viewport, if not we pause the time
    // need this check since ad can render from the height of viewport.
    if (!this.scrollMonitor.isInViewport) {
      this.timer.pause();
      this.timerWasPaused = this.timer.isPaused();
    }

    if (this.recycleOnBreakpoint) {
      this.setupRecycleOnBreakpoint();
    }

    const {
      empty = false,
      requestTime = null,
      ...additionalData
    } = data;

    this.renderEventData = { empty, requestTime, additionalData };
    this.fireRenderCallbacks(this.renderEventData);
  }

  onRender(fn) {
    if (this.adLifecycle.is(AD_STATUS.RENDERED)) {
      fn.call(this, this.renderEventData, this);
      return;
    }
    this.onRenderCallbacks.push(fn);
  }

  fireRenderCallbacks(event = {}) {
    this.onRenderCallbacks.forEach((fn) => fn.call(this, event, this));
  }

  setupElementObserver() {
    this.elementObserver = new MutationObserver((mutationRecord) => {
      mutationRecord.forEach((mutation) => {
        mutation.removedNodes.forEach((node) => {
          if (node.id === this.id) {
            // MPS will remove the ad element if not rendered fast enough, so ads rarely render
            // on very slow connections. This will allow the ad to render eventually
            insertElement('div', { id: this.id }, this.element);
          }
        });
      });
    });

    this.elementObserver.observe(this.element, { subtree: true, childList: true });
  }

  setupRefreshInterval() {
    this.startTimer();

    this.scrollMonitor.enterViewport(this.startTimer);
    this.scrollMonitor.exitViewport(this.pauseTimer);

    window.addEventListener('focus', this.resumeTimerOnFocus);
    window.addEventListener('blur', this.pauseTimeOnBlur);

    this.timer.addEventListener(this.recycleWhenTimesUp);
  }

  setupRenderOnView() {
    this.scrollMonitor.enterViewport(this.render);
  }

  setupRecycleOnView() {
    this.scrollMonitor.enterViewport(this.recycle);
  }

  setupRecycleOnBreakpoint() {
    const { breakpoints } = MPS.config;

    if (!breakpoints.length) {
      return;
    }

    onResize((previousWidth, newWidth) => {
      const requiresRefresh = !!breakpoints.find((breakpoint) => isBetween(
        breakpoint,
        previousWidth,
        newWidth,
      ));

      if (requiresRefresh) {
        this.recycle();
      }
    });
  }

  startTimer = () => {
    this.timer.start();
  }

  pauseTimer = () => {
    this.timer.pause();
  }

  renderOnFocus() {
    const onFocus = () => {
      this.setupRenderOnView();
      window.removeEventListener('focus', onFocus);
    };

    window.addEventListener('focus', onFocus);
  }

  recycleOnFocus() {
    const onFocus = () => {
      this.setupRecycleOnView();
      window.removeEventListener('focus', onFocus);
    };

    window.addEventListener('focus', onFocus);
  }

  resumeTimerOnFocus = () => {
    if (this.timerWasPaused || !this.scrollMonitor.isInViewport) {
      return;
    }
    this.startTimer();
  }

  pauseTimeOnBlur = () => {
    this.timerWasPaused = this.timer.isPaused();
    this.pauseTimer();
  }

  removeScrollListeners() {
    this.scrollMonitor.off('enterViewport', this.startTimer);
    this.scrollMonitor.off('enterViewport', this.setupRenderOnView);
    this.scrollMonitor.off('enterViewport', this.setupRecycleOnView);
    this.scrollMonitor.off('exitViewport', this.pauseTimer);
  }

  removeListeners() {
    this.timer.reset();
    this.timer.removeEventListener(this.recycleWhenTimesUp);

    this.removeScrollListeners();

    window.removeEventListener('focus', this.resumeTimerOnFocus);
    window.removeEventListener('blur', this.pauseTimeOnBlur);
  }

  recycleWhenTimesUp = () => {
    if (this.timer.getTotalSeconds() >= this.refreshInterval) {
      this.recycle();
    }
  }
}

export default Ad;
