import loadScript from 'lib/loadScript';
import Preprocessor from './Preprocessor';

const admantxData = [];
let savedId = null;

const Admantx = (preprocessorData) => {
  const { url, id = savedId } = preprocessorData;
  if (!url || !id || url === 'undefined' || id === 'undefined') {
    throw new Error('Admantx requires a canonical url and publisher key');
  }

  savedId = id;

  const init = () => {
    const escapedUrl = window.escape(url);

    const reqObj = {
      decorator: 'template.nbc_template',
      key: id,
      method: 'descriptor',
      filter: 'default',
      mode: 'async',
      type: 'URL',
      body: escapedUrl,
    };

    const request = window.escape(JSON.stringify(reqObj));
    const admantxUrl = `https://usasync01.admantx.com/admantx/service?request=${request}`;

    if (!window.admantx_callback) { // only allow one admantx request at a time
      window.admantx_callback = (data) => {
        admantxData.push(data);
        window.admantx_callback = null;
      };

      loadScript(admantxUrl, {
        attributes: {
          async: true,
        },
      });
    } else {
      // eslint-disable-next-line no-console
      console.log(`admantx already requested ${url}`);
    }
  };

  const test = () => window.admantx_callback === null;

  const setTargeting = () => {
    let targetingObj = {};

    const dlen = admantxData.length;
    if (dlen) {
      targetingObj = { adg: admantxData[dlen - 1].admants };
    }

    return { targeting: targetingObj };
  };

  return new Preprocessor(init, test, setTargeting);
};

export default Admantx;
