import * as Preprocessors from './index';

const setupPreprocessors = (preprocessors) => Promise.all(
  Object
    .entries(preprocessors)
    .map(([key, config]) => {
      if (!Preprocessors[key]) {
        throw new Error(`MPS does not support a '${key}' preprocessor`);
      }
      return Preprocessors[key](config);
    }),
);

// Preprocessors usually append data to the ad calls themselves and
// include things like Xfinity or Admantix
const getPreprocessorTargeting = async (preprocessors) => {
  const preprocessorResults = await setupPreprocessors(preprocessors);

  const targeting = {};

  preprocessorResults
    .map((result) => result?.targeting)
    .forEach((values) => {
      Object.assign(targeting, values);
    });

  return targeting;
};

export default getPreprocessorTargeting;
