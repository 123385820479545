/* eslint-disable no-underscore-dangle */
import loadScript from 'lib/loadScript';
import resolveTargeting from 'lib/Ads/resolveTargeting';
import getPreprocessorTargeting from 'lib/Ads/preprocessors/getPreprocessorTargeting';
import { setGptTargeting, clearGptTargeting } from 'lib/Ads/googlePublisherTags';
import { MPS_QUEUE_TYPES, insertElement, getMpsQueue } from 'lib/Ads/adUtils';

let i = 0;
const seqNumber = () => { i += 1; return i; };
const generateID = (slot) => `div-mps-ad-${slot}-${seqNumber()}`;

let globalMPSUrl = null;
let globalAdUnit = null;

class MPS {
  static ready = Promise.resolve();

  static configured = undefined;

  static config = null;

  static getAdId = (slot) => generateID(slot);

  static async configure(options = {}) {
    if (!window) {
      return;
    }

    const {
      breakpoints = [],
      vendors = {},
      preprocessors = {},
      targeting = {},
      refreshInterval,
    } = options;

    if (MPS.configured) {
      await MPS.reconfigure(options);
      return;
    }

    MPS.configured = new Promise((resolve) => {
      MPS.ready = Promise.resolve()
        .then(async () => {
          const preprocessorTargeting = await getPreprocessorTargeting(preprocessors);

          MPS.targeting = { ...targeting, ...preprocessorTargeting };
          MPS.config = { breakpoints, vendors, refreshInterval };

          globalMPSUrl = vendors?.mpsUrl ?? '';
          globalAdUnit = vendors?.gptAdUnitPath ?? '';

          const host = (vendors?.mpsUrl?.replace('https://', '')?.split('/'))?.[0];

          window.mpsopts = { host, updatecorrelator: false };
          const resolvedTargeting = resolveTargeting({
            targeting: MPS.targeting,
            adUnit: globalAdUnit,
            mpscall: window.mpscall,
            mpsUrl: globalMPSUrl,
          });
          setGptTargeting(resolvedTargeting?.gpt);
          window.mpscall = resolvedTargeting.mps;

          loadScript(globalMPSUrl, {
            attributes: {
              async: true,
            },
          }).thenOnFirstLoad(() => {
            resolve();
          });
        });
    }).then(async () => {
      await MPS.ready;
    });
  }

  static async reconfigure(options = {}) {
    if (!MPS.configured) {
      throw new Error('MPS has not been configured');
    }

    const {
      preprocessors = {},
      targeting = {},
      vendors = {},
    } = options;

    MPS.ready = Promise.resolve()
      .then(async () => {
        const preprocessorTargeting = await getPreprocessorTargeting(preprocessors);

        MPS.targeting = { ...targeting, ...preprocessorTargeting };
        MPS.config = { ...MPS.config, vendors };

        globalAdUnit = vendors?.gptAdUnitPath ?? '';

        const resolvedTargeting = resolveTargeting({
          targeting: MPS.targeting,
          adUnit: globalAdUnit,
          mpscall: window.mpscall,
          mpsUrl: globalMPSUrl,
        });

        clearGptTargeting();
        setGptTargeting(resolvedTargeting?.gpt);

        getMpsQueue(MPS_QUEUE_TYPES.ready).push(() => {
          window.mpscall = resolvedTargeting.mps;
          window.mps.makeRequest('more');

          // eslint-disable-next-line no-underscore-dangle
          window.mps._correlator.update();
        });
      });

    await MPS.ready;
  }

  static async createAd(options, onComplete) {
    // ensure MPS is configured and ready before adding functions to the MPS queues
    await MPS.configured;
    await MPS.ready;

    const {
      id,
      slot,
      element,
      targeting = {},
    } = options;

    const adId = id || generateID(slot);
    insertElement('div', { id: adId }, element);

    const ad = { id: adId, slot };
    getMpsQueue(MPS_QUEUE_TYPES.adload).push((eo) => {
      // eslint-disable-next-line no-underscore-dangle
      const mps = eo._mps; // don't assign this property to the ad, it will mutate

      // eslint-disable-next-line no-underscore-dangle
      if (mps._slot === slot) {
        ad.advar = mps.advar;
        onComplete({ empty: eo.isEmpty, mps, eo });
      }
    });

    return new Promise((resolve) => {
      getMpsQueue(MPS_QUEUE_TYPES.ready).push(() => {
        // pos is the only ad specific parameter, the rest are "page" context variables.
        const adTargeting = { pos: slot, ...targeting };
        const resolvedTargeting = resolveTargeting({
          targeting,
          adUnit: globalAdUnit,
          mpscall: window.mpscall,
          mpsUrl: globalMPSUrl,
        });

        // serialize to 'a=1;a=2;b=3;' format
        const customTargeting = Object.keys(resolvedTargeting.mps).reduce((out, key) => {
          if (window.mpscall[key] !== resolvedTargeting[key]) {
            return `${out}${key}=${resolvedTargeting.mps[key]};`;
          }
          return out;
        }, '');

        ad.adTargeting = adTargeting;
        ad.customTargeting = customTargeting;

        resolve(ad);
      });
    });
  }

  static renderAd(ad) {
    return new Promise((resolve) => {
      const { id, slot, customTargeting } = ad;

      getMpsQueue(MPS_QUEUE_TYPES.ready).push(() => {
        if (typeof window !== 'undefined' && ['news', 'msnbc'].includes(window?.__vertical) && window?.__isarticle && slot === 'boxinline') {
          setTimeout(() => {
            window.mps.cloneAd(`#${id}`, slot, customTargeting);
          }, 2000);
        } else {
          window.mps.cloneAd(`#${id}`, slot, customTargeting);
        }
        resolve();
      });
    });
  }

  static destroyAd(ad) {
    return new Promise((resolve) => {
      getMpsQueue(MPS_QUEUE_TYPES.ready).push(() => {
        const { id } = ad;
        window.mps._remove(document.getElementById(id)); // eslint-disable-line
        if (typeof ad.advar !== 'undefined') {
          delete (window.gpt[ad.advar]);
        }
        resolve();
      });
    });
  }

  static recycleAd(ad) {
    return new Promise((resolve) => {
      getMpsQueue(MPS_QUEUE_TYPES.ready).push(() => {
        if (typeof ad.advar !== 'undefined') {
          window.mps.refreshAds([window.gpt[ad.advar]]);
        }
        resolve();
      });
    });
  }
}

export default MPS;
