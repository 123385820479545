const ONE_SECOND = 1000;
const SECOND_EVENT = 'secondsUpdated';

class AdTimer {
  intervalId = null;

  eventEmitter = document?.createElement('span');

  running = false;

  paused = false;

  totalSeconds = 0;

  start = () => {
    if (this.running) {
      return;
    }

    if (!this.paused) {
      this.intervalId = setInterval(this.updateTimer, ONE_SECOND);
    }

    this.running = true;
    this.paused = false;
  }

  updateTimer = () => {
    if (!this.paused) {
      this.totalSeconds += 1;
      this.eventEmitter?.dispatchEvent(new CustomEvent(SECOND_EVENT));
    }
  }

  pause = () => {
    this.running = false;
    this.paused = true;
  }

  reset = () => {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
    this.totalSeconds = 0;
    this.running = false;
    this.paused = false;
    this.start();
  }

  isPaused = () => this.paused;

  getTotalSeconds = () => this.totalSeconds;

  addEventListener = (listener) => {
    this.eventEmitter?.addEventListener(SECOND_EVENT, listener);
  }

  removeEventListener = (listener) => {
    this.eventEmitter?.removeEventListener(SECOND_EVENT, listener);
  }
}

export default AdTimer;
