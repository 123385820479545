/*
 * On Resize utility. Basic throttling included.
 */
import { requestAnimationFrame } from 'lib/Ads/adUtils';

const callbacks = [];
const width = () => window.innerWidth;

let initiated = false;
let inProgress = false;
let previousWidth = 0;

function onResizeHandler() {
  if (inProgress) {
    return;
  }

  inProgress = true;

  requestAnimationFrame(() => {
    const currentWidth = width();

    callbacks.forEach((callback) => {
      callback(previousWidth, currentWidth);
    });

    previousWidth = currentWidth;
    inProgress = false;
  });
}

export default function onResize(fn) {
  callbacks.push(fn);

  if (!window || initiated) {
    return;
  }

  initiated = true;
  previousWidth = width();

  window.addEventListener('resize', onResizeHandler);
}
