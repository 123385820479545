const getGoogleTag = () => {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];
  return window.googletag;
};

export const setGptTargeting = (targeting = {}, gptslot = null) => {
  getGoogleTag().cmd.push(() => {
    const scope = (gptslot || getGoogleTag()?.pubads());

    // Call setTargeting on a gptslot or globally via pubads()
    Object.keys(targeting).forEach((key) => {
      if (typeof targeting[key] !== 'undefined') {
        scope?.setTargeting(key, targeting[key]);
      }
    });
  });
};

export const clearGptTargeting = () => {
  getGoogleTag().cmd.push(() => {
    getGoogleTag()?.pubads()?.clearTargeting();
  });
};
