import { adUnitToCat } from 'lib/Ads/adUtils';

const getFallbackSiteFromMpsUrl = (url) => url?.split('ext/load-')?.[1]?.split('.js')?.[0] ?? null;

const resolveTargeting = ({
  targeting, adUnit, mpscall, mpsUrl,
}) => {
  const {
    ksg, kuid, adg, xfinity, site, type, pos, pageid, referrer, ...otherTargetingProps
  } = targeting;

  const gptTargeting = {
    ksg, kuid, adg, xfinity, referrer,
  };

  const mpsTargeting = mpscall || {}; // original mpscall contains default settings

  // everything else gets stuffed in cag
  Object.keys(otherTargetingProps).forEach((propKey) => {
    if (typeof otherTargetingProps[propKey] === 'string') {
      mpsTargeting[`cag[${propKey}]`] = otherTargetingProps[propKey];
    } else if (Array.isArray(otherTargetingProps[propKey])) {
      mpsTargeting[`cag[${propKey}]`] = otherTargetingProps[propKey].join('|');
    }
  });

  mpsTargeting.adunit = adUnit;
  mpsTargeting.type = type;
  mpsTargeting.content_id = pageid;
  mpsTargeting.referrer = referrer;
  mpsTargeting.cat = adUnitToCat(adUnit);

  const fallbackSite = getFallbackSiteFromMpsUrl(mpsUrl);
  if (mpsTargeting.site || fallbackSite) {
    mpsTargeting.site = mpsTargeting.site || fallbackSite;
  }

  return { mps: mpsTargeting, gpt: gptTargeting };
};

export default resolveTargeting;
