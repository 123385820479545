import loadScript from 'lib/loadScript';
import Preprocessor from './Preprocessor';

const XFINITY_SCRIPT = 'https://polaris.xfinity.com/polaris.js';

const VALID_DOMAINS = [
  'assets.nbcnews.com',
  'assets.today.com',
];

const VALID_REFERRERS = [
  'xfinity.comcast.net',
  'xfinity.today.com',
  'xfinity.nbcnews.com',
  'my.xfinity.com',
];

const COOKIE_NAME = 'P7';
const COOKIE_VALUE = 'X';

const isValidReferrer = () => {
  const refUrl = document?.referrer;
  const refParts = refUrl?.split('//');
  if (!refUrl || refParts.length < 2) {
    return false;
  }

  return VALID_REFERRERS.filter((host) => (refParts[1].indexOf(host) === 0)).length;
};

const addCookies = () => {
  VALID_DOMAINS.forEach((domain) => {
    const image = new window.Image();
    const date = new window.Date();
    image.src = `//${domain}/rendering/crossdomaincookiepush.ashx/X?d=${date.getTime()}`;
  });
};

const readCookie = (name) => {
  const anyCookies = document.cookie;
  const position = anyCookies.toUpperCase().indexOf(`${name}=`);
  if (position !== -1) {
    const start = position + name.length + 1;
    const end = anyCookies.indexOf(';', start) !== -1
      ? anyCookies.indexOf(';', start)
      : anyCookies.length;

    return unescape(anyCookies.substring(start, end));
  }
  return '';
};

const shouldLoadHeader = () => {
  if (readCookie(COOKIE_NAME) === COOKIE_VALUE) {
    return true;
  }

  if (isValidReferrer()) {
    addCookies();
    return true;
  }

  return false;
};

const Xfinity = () => {
  const init = () => {
    const hasXfinity = document.querySelector('xc-header');
    if (shouldLoadHeader() && (typeof (hasXfinity) === 'undefined' || hasXfinity === null)) {
      document.body.classList.add('xfinity');
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = '<xc-header tab="myxfinity" client-id="nbcnews"></xc-header>';
      const headerNode = tempDiv.childNodes[0];
      document.body.insertBefore(headerNode, document.body.childNodes[0]);
      loadScript(XFINITY_SCRIPT, {
        attributes: {
          async: true,
        },
      });
    }
  };

  const test = () => document.body.classList.contains('xfinity');

  const setTargeting = () => (document.body.className.includes('xfinity')
    ? { targeting: { xfinityTag: 'xfinity' } }
    : {});

  return new Preprocessor(init, test, setTargeting);
};

export default Xfinity;
