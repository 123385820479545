import { requestAnimationFrame } from 'lib/Ads/adUtils';

class Preprocessor {
  constructor(init, testCondition, setTargeting, maxWaitTime = 1500) {
    this.startTime = Date.now();
    this.maxWaitTime = maxWaitTime;
    this.testCondition = testCondition;
    this.setTargeting = setTargeting;

    this.promise = new Promise((resolve, reject) => {
      try {
        init();
        this.resolveAfterConditionOrMaxTime(resolve);
      } catch (error) {
        reject(error);
      }
    });
  }

  then(...args) {
    return this.promise.then(...args);
  }

  catch(...args) {
    return this.promise.catch(...args);
  }

  resolveAfterConditionOrMaxTime(resolve) {
    if (this.testCondition() === true) {
      resolve(this.setTargeting());
      return;
    }

    if (Date.now() - this.startTime > this.maxWaitTime) {
      resolve(this.setTargeting());
      return;
    }

    requestAnimationFrame(() => {
      this.resolveAfterConditionOrMaxTime(resolve);
    });
  }
}

export default Preprocessor;
