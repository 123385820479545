export const AD_STATUS = {
  CREATING: 'creating',
  CREATED: 'created',
  REQUESTING: 'requesting',
  REQUESTED: 'requested',
  RENDERING: 'rendering',
  RENDERED: 'rendered',
  RECYCLING: 'recycling',
  RECYCLED: 'recycled',
  DESTROYING: 'destroying',
  DESTROYED: 'destroyed',
};

const PROCESSING_STATUSES = [
  AD_STATUS.CREATING,
  AD_STATUS.REQUESTING,
  AD_STATUS.RENDERING,
  AD_STATUS.RECYCLING,
  AD_STATUS.DESTROYING,
];

const FINISHED_STATUSES = [
  AD_STATUS.REQUESTED,
  AD_STATUS.RENDERED,
  AD_STATUS.RECYCLED,
  AD_STATUS.DESTROYED,
];

class AdLifecycleManager {
  statuses = {
    [AD_STATUS.CREATING]: false,
    [AD_STATUS.CREATED]: false,
    [AD_STATUS.REQUESTING]: false,
    [AD_STATUS.REQUESTED]: false,
    [AD_STATUS.RENDERING]: false,
    [AD_STATUS.RENDERED]: false,
    [AD_STATUS.RECYCLING]: false,
    [AD_STATUS.RECYCLED]: false,
    [AD_STATUS.DESTROYING]: false,
    [AD_STATUS.DESTROYED]: false,
  }

  is(key) {
    return this.statuses[key];
  }

  isProcessing() {
    return PROCESSING_STATUSES.some((status) => this.statuses[status]);
  }

  set(key, value) {
    if (key in this.statuses) {
      this.statuses[key] = value;
    }
    return this;
  }

  clearAllStatuses() {
    Object.keys(AD_STATUS).forEach((status) => {
      if (AD_STATUS[status] !== AD_STATUS.CREATED) {
        this.statuses[AD_STATUS[status]] = false;
      }
    });
    return this;
  }

  clearFinishedStatuses() {
    FINISHED_STATUSES.forEach((status) => {
      this.statuses[status] = false;
    });
    return this;
  }

  doneProcessing() {
    PROCESSING_STATUSES.forEach((status) => {
      this.statuses[status] = false;
    });
    return this;
  }
}

export default AdLifecycleManager;
