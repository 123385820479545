const scriptsLoaded = {};

export default function LoadScript(
  url,
  { location = 'head', attributes = {}, force = false } = {},
) {
  if (!force && scriptsLoaded[url]) {
    return scriptsLoaded[url];
  }

  scriptsLoaded[url] = new Promise((resolve) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.onload = resolve;

    Object.keys(attributes).forEach((key) => {
      const value = attributes[key];

      if (typeof value === 'boolean') {
        if (!value) {
          return;
        }
        scriptTag.setAttribute(key, key);
        return;
      }

      scriptTag.setAttribute(key, value);
    });

    if (location instanceof Node) {
      location.appendChild(scriptTag);
      return;
    }

    const node = document.querySelector(location);
    if (node) {
      node.appendChild(scriptTag);
    }
  });

  // add a method for doing things only the first time the script gets loaded, like `init` code
  scriptsLoaded[url].thenOnFirstLoad = (cb) => {
    // set to a no-op so the next time you call this method, it won't do anything
    // but return the promise itself so you can continue to call `.then`s
    scriptsLoaded[url].thenOnFirstLoad = () => scriptsLoaded[url];
    return scriptsLoaded[url].then(cb);
  };

  return scriptsLoaded[url];
}
