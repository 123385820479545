import Ad from 'lib/Ads/Ad';
import MPS from 'lib/Ads/MPS';
import AdRegister from 'lib/Ads/AdRegister';
import { getAdOptionsFromElement } from 'lib/Ads/adUtils';

// eslint-disable-next-line no-underscore-dangle
window.__nbcnd__ = window.__nbcnd__ || {};

// eslint-disable-next-line no-underscore-dangle
window.__nbcnd__.adQueue = window.__nbcnd__.adQueue || [];

function createAd(el) {
  const initializedAd = AdRegister.findByElement(el);
  return initializedAd || new Ad(el, getAdOptionsFromElement(el));
}

function runCallback(fn) {
  fn(MPS, createAd);
}

if (!window.__mpsconfig) { // eslint-disable-line no-underscore-dangle
  throw new Error('window.__mpsconfig has not been set. set it or else.');
}

MPS.configure(window.__mpsconfig); // eslint-disable-line no-underscore-dangle

Array.prototype.forEach.call(document.querySelectorAll('[data-mps]'), createAd);

window.__nbcnd__.adQueue.forEach(runCallback); // eslint-disable-line no-underscore-dangle
window.__nbcnd__.adQueue.length = 0; // eslint-disable-line no-underscore-dangle
window.__nbcnd__.adQueue.push = runCallback; // eslint-disable-line no-underscore-dangle
